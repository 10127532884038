.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(255, 255, 255, 0.6);
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: #9F4DB6;
}